import React, { useEffect } from 'react'
import io from 'socket.io-client'

let socket

const App = ({  }) => {
    const ENDPOINT = '/api'

    useEffect(() => {
        socket = io(ENDPOINT);
        console.log(socket)
    }, [ENDPOINT])

    return (null)
}

export default App
